import React from "react";
import Layout from "../layout/Layout";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import brandDefault from "../assets/images/brand-default.png";
import Pagination from "../components/common/Pagination";
import { pagination } from "../utils/common";

const BrandsPageTemplate = ({ location, brands, pageContext }) => {
  const { currentPage, totalPages } = pageContext;

  return (
    <div className="page-wrapper">
      <div className="page-heading-section text-center page-block">
        <BreadCrumbs location={location} />
        <h2 className="page-title">{"Brands"}</h2>
      </div>

      <div className="container">
        <div className="page-layout">
          <ul className="brands-list">
            {brands.map((brand) => (
              <li key={brand.id}>
                <Link to={`${brand.url}`}>
                  <div className="brand-figure">
                    <img
                      src={brand.imageUrl || brandDefault}
                      alt={brand.imageUrl}
                    />
                  </div>
                  <p>{brand.name}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={totalPages}
          onPageChange={(page) => pagination(page, "brands")}
        />
      </div>
    </div>
  );
};

BrandsPageTemplate.propTypes = {
  brands: PropTypes.array,
};

function BrandsPage({ data, location, pageContext }) {
  const allBrands = data.allBigCommerceBrands.edges.map((item) => {
    const {
      id,
      custom_url: { url },
      name,
      image_url: imageUrl,
    } = item.node;

    return {
      id,
      url,
      name,
      imageUrl,
    };
  });
  return (
    <Layout>
      <BrandsPageTemplate
        location={location}
        pageContext={pageContext}
        brands={allBrands}
      />
    </Layout>
  );
}

BrandsPage.propTypes = {
  data: PropTypes.shape({
    allBigCommerceBrands: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.object,
};

export default BrandsPage;

export const brandsPageQuery = graphql`
  query getAllBrands($skip: Int!, $limit: Int!) {
    allBigCommerceBrands(limit: $limit, skip: $skip) {
      edges {
        node {
          id
          name
          custom_url {
            url
          }
          image_url
        }
      }
    }
  }
`;
